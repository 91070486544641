.admin-home-page-container {
    min-height: 100vh;
    margin-bottom: 20vh;
}

.admin-controls-container {
    list-style: none;
    margin-left: 10vw;
    margin-right: 10vw;
}
.admin-page-navbar {
    display: flex;
    color: #ffffff;
    background-color: #2885cb;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    margin-top: 0;
    padding: 0.5em;
    font-size: 1.5em;
    margin-bottom: 12vh;
    justify-content: space-between;
}

.admin-controls-container li {
    cursor: pointer;
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    margin: 0.5em;
    background-color: #0095ff;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
}

.admin-controls-container li:hover,
.admin-controls-container li:focus {
    background-color: #07c;
}

.admin-controls-container li:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.admin-controls-container li:active {
    background-color: #0064bd;
    box-shadow: none;
}

.login-btn-admin{
    padding: 0.5em !important;
    font-size: 45%;
}