.container {
  min-height: 80vh;
  padding: 0.4rem 0.4rem;
  margin: 0.4rem 0.4rem;
}

.heading {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 2rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-decoration: underline;
  text-align: center;
}

.btncoupon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 0.5rem;
  font-weight: 550;
  cursor: pointer;
  color: white;
  border-radius: 10px;
  background: #068ff0;
  padding: 0.5rem 0.25rem;
  transition: all 200ms;
}

@media (min-width: 1024px) {
  .btncoupon {
    width: 30%;
  }
}

.btncoupon:hover {
  background: #026ab4;
}

.parentdiv1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.coupongrid {
  display: grid;
  padding: 0.5rem;
  width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.75rem;
}

.renderdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (min-width: 768px) {
  .coupongrid {
    padding: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .coupongrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.ptag {
  text-align: center;
  width: 100%;
  font-size: x-large;
  margin-top: 2rem;
}
