.order_container {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.75em;
    margin-bottom: 1em;
    min-height: 100vh;
    margin-top: 0.5em;
}
.orders{
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.order_item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: fit-content;
}

.order_item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.order_title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    border: solid 1px #ccc;
    border-radius: 0.5em;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
  }
  
.order_title::before {
  content: "\23F5";
  margin-right: 0.5em;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.order_title .open::before {
  /* transform: rotate(90deg); */
  content: "";
}

.order_title .hover::before {
  /* transform: rotate(0deg); */
}
.order_title:hover {
  color: black;
  background-color: #b6d8f2;
}
.order_title .open {
  color: black;
  background-color: #b6d8f2;
}

.order_content {
    padding: 1em 1.5em;
    border-radius: 1.5em;
    min-height: 5vh;
    font-size: 1em;
    border: 2px solid #b6d8f2;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
}

.accept-button,
.reject-button {
    margin-left: 1em;
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 0.8em;
}

.reject-button {
    background-color: #ff6961;
    /* Red color for reject button */
    color: white;
}