.chat-container {
    display: flex;
    flex-direction: column;
    min-height: 16rem;
    font-family: 'Manrope', sans-serif;
    padding: 1rem;
    margin: 2rem 3rem;
  }
  
  @media (min-width: 1024px) {
    .chat-container {
      flex-direction: row;
      padding: 0.25rem;
    }
  }
  
  @media (max-width: 726px) {
    .chat-container {
      flex-direction: column;
      padding: 0.25rem;
    }
  }
  
  .users-list-container {
    width: 100%;
    border-bottom: 1px solid #d1d5db;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 1024px) {
    .users-list-container {
      width: 45%;
      margin-bottom: 0;
      border-right: 1px solid #d1d5db;
    }
  }
  
  .title {
    font-weight: 600;
    font-size: 1.125rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  
  .users-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    height: 8rem;
    overflow-y: auto;
  }
  
  @media (min-width: 1024px) {
    .users-list {
      height: 20rem;
    }
  }
  
  .user-item {
    cursor: pointer;
    padding: 0.5rem;
    margin-left: 0.75rem;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    gap: 1.25rem;
    background: rgb(215, 213, 213);
    transition: background-color 0.2s, color 0.2s;
  }
  
  .user-item:hover {
    background-color: #a8a8a9;
    color: #000;
  }
  
  .selected {
    background-color: #02B290;
    color: #fff;
  }
  
  .unread {
    border: 2px solid #02B290;
    color: #374151;
  }
  
  .unread-indicator {
    display: inline-block;
    height: 0.5rem;
    width: 0.5rem;
    background-color: #02B290;
    border-radius: 50%;
  }
  
  .chat-window-container {
    flex-grow: 1;
    width: 100%;
    padding: 1rem;
    min-height: 16rem;
  }
  
  .chat-title {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

.check {
    display: flex;
    justify-items: center;
    align-items: center;
}
  
.homebtn {
    width: 30%;
    height: 10%;
}

  .close-icon {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    fill: red;
  }
  
  .chat-window {
    border: 1px solid #d1d5db;
    padding: 1rem;
    height: 24rem;
    overflow-y: scroll;
  }
  
  .message {
    margin-bottom: 0.5rem;
  }
  
  .message-content {
    padding: 0.75rem;
    border-radius: 0.5rem;
    max-width: 80%;
    word-break: break-word;
  }
  
  .admin-message .message-content {
    background-color: #e5e7eb;
    color: #000;
    width: 50%;
  }
  
  .user-message .message-content {
    background-color: #000;
    color: #fff;
    width: 50%;
  }
  
  .message-input-container {
    display: flex;
    margin-top: 1rem;
    gap: 0.5rem;
    align-items: center;
  }
  
  .message-input {
    padding: 0.5rem;
    width: 100%;
    border: 1px solid #d1d5db;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    height: 2.75rem;
  }
  
  .message-input:focus {
    border-color: #02B290;
    outline: none;
  }
  
  .send-button {
    padding: 0.75rem 1rem;
    background-color: #02B290;
    color: #fff;
    border-radius: 0.375rem;
    transition: background-color 0.2s;
  }
  
  .send-button:hover {
    background-color: rgba(2, 178, 144, 0.9);
  }
  
  .no-user-selected {
    text-align: center;
  }
  

  .admin-message {
    display: flex;
    justify-content: flex-end;
  }
  
  .admin-message .message-content {
    background-color: #e5e7eb;
    color: #000;
    align-self: flex-start;
    border-radius: 0.5rem 0.5rem 0.5rem 0;
  }
  
  .user-message {
    display: flex;
    justify-content: flex-start;
  }
  
  .user-message .message-content {
    background-color: #000;
    color: #fff;
    align-self: flex-end;
    border-radius: 0.5rem 0.5rem 0 0.5rem;
  }
  