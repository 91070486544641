.footer{
   margin-top: auto;
}
.footer_links_container{
    background-color: #93cbf6;
    padding-left: 5em;
    padding-right: 5em;
    font-size: 1.1em;
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}
.useful_links_container{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    align-items: left;
    list-style: none;
    
}
.useful_links_container h3{
    font-weight: 500;
    font-size: 1.5em;
    text-align: left;
    color: #0474ca;
}
.useful_links_container li{
    list-style: none;
    padding-top: .9em;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: 500;
}
a{
    text-decoration: none;
    color: black;
}
.contact_links{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    align-items: left;
    list-style: none;
    text-decoration: none;
    color: black;
}
.contact_links h3{
    font-weight: 500;
    font-size: 1.5em;
    text-align: left;
    color: #0474ca;
}
.contact_links li{
    list-style: none;
    padding-top: 1em;
    font-weight: 500;
}
.socialmedia_links{
    display: flex;
    justify-content: space-around;
    list-style: none;
    align-items: center;
    margin-top: 25px;
}

@media screen and (max-width: 767px) {
    .footer{
        font-size: 0.8em;
    }
    .socialmedia_links{
        padding-top: 1em;
    }
    .footer_links_container{
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 1em;
        padding-bottom: 1em;    
        justify-content: space-between;
        flex-direction: column;
    }
    .footer_links_container li{
        font-size: 1.2em;
    }
    .useful_links_container{
        margin-bottom: 10px;
    }
    .useful_links_container h3{
        font-size: 2em;
        margin: 5px 0;
    }
    .useful_links_container li{
        padding-top: .7em;
    }
    .contact_links h3{
        font-size: 2em;
        margin: 10px 0;
    }
    .contact_links li{
        padding-top: .7em;
    }
    .socialmedia_links{
        margin-top: 5px;
    }
}
.socialmedia_links li{
    padding: 0.5em;
    transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.socialmedia_links li:hover{
    cursor: pointer;
    color: white;
}
.copyright_text{
    background-color: #517894;
    font-size: 2vh;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: white;
    padding-top: 0.5em;
    text-align: center;
}

.font-priv-size {
    font-size: 125%;
}

@media screen and (max-width: 767px) {
    .font-priv-size {
        font-size: 100%;
    }
}