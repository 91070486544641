.admin-login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    padding-top: 10vh;
    width: 35vw;
    height: 35vh;
    margin-bottom: 20vh;
    border: 1px solid black;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}