.edit-product-table-container{
    margin-left: 5vw;
    margin-right: 5vw;
    border-collapse: collapse;
    font-size: 90%;
}
tr,td,thead,th{
    border-collapse: collapse;
}
.image_edit_container{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
}