@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500&family=Roboto:wght@300;400;500;700&display=swap');
.main-container{
    background-color: #cee6f8;
}
.product-form-container{
    font-family: 'Montserrat';
    background-color: white;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;;
    /* border: 2px solid red; */
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1em;
    margin-top: 18vh;
    margin-bottom: 25vh;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
}
.product-form-container h2{
    text-align: center;
    border-bottom: 0.1em solid black;
}
.product-form{
  width: 100%;
}
.form-group{
    margin-left: auto;
    margin-right: auto;
}
.form-input{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 0.8em;
    border: 0.1em solid #ccc;
    outline: #537caa92;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth effect */
}
.form-input:focus,
.form-input:hover {
  border-color: #6ba1de92; /* Change border color on focus/hover */
  box-shadow: #0366d64d 0px 0px 0px 3px;
}
.input-help{
  font-size: 60%;
  font-weight: 500;
  font-style: italic;
  margin-top: 0.3em;
}
textarea {
    font-style: 'Montserrat , sans-serif';
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    outline: none;
  }
  
  textarea:focus,
  textarea:hover {
    border-color: #555;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

.add-product-btn {
  appearance: none;
  backface-visibility: hidden;
  background-color: #27ae60;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.add-product-btn:hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.add-product-btn:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.add-product-btn:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}












/* Image Upload Container CSS */


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #475F7B;
}

a, a:hover, a:focus {
  text-decoration: none;
  transition: 0.5s;
  outline: none;
}

/* ------------ */

.card {
  /* box-shadow: 0px 4px 8px rgb(0 0 0 / 16%); */
  border: 2px dotted #00000029;
  border-radius: 0.5em;
  overflow: hidden;
  margin-bottom: 2em;
  background-color: #fff;
}
.card-body {
  padding: 30px;
}

.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.image-upload-heading h6 {
  margin-bottom: 0;
  font-size: 0.7em;
  font-weight: 600;
}
.file-upload-container {
  margin-bottom: 1em;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 0.2em;
  max-height: fit-content;
  min-height: 12vh;
  position: relative;
  overflow: hidden;
  padding: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 0.6em;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link{
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover{
  text-decoration: none;
}
.file-attach-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img{
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 0.6em;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 0.5em;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 0.7em;
  color: red;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child{
  margin-bottom: 0;
}

