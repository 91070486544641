.aboutus_container{
    margin-left: 10em;
    margin-right: 10em;
    margin-bottom: 2em;
}
.aboutus_heading{
    color: #88a478;
    font-size: 2.5em;
}

.para{
    font-size: 1.2em;
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-around;
}
.para_text{
    margin-left: 7em;
}
.para_heading
{
    color: #068ff0;
    font-weight: 700;
}

.commitment{
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .aboutus_container{
        margin-left: 1em;
        margin-right: 1em;
    }
    .para{
        font-size: 1em;
        flex-direction: column;
    }
    .para_text{
        margin-left: 0;
    }
}