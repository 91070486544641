.search-form-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.product-search-form{
    /* min-height: 40vh; */
    max-height: fit-content;
    width: 35vw;
    border-radius: 1em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1em;
}


.search-options button {
    background-color: #f0f0f0; /* Default background color */
    border: none;
    padding: 0.5em;
    cursor: pointer;
    font-size: 0.7em;
    margin-bottom: 1em;
}


.search-options button:first-child {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
}

.search-options button:last-child {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
}


.search-options button.active {
    background-color: #0095ff; /* Change to your desired active color */
    color: #fff; /* Change to your desired text color */
}

.search-form-input{
    width: calc(95%);
    margin-top: 0.5em;
    font-size: 1em;
    margin-bottom: 0.5em;
}

.search-product-btn {
  background-color: #0095ff;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 0.5em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.search-product-btn:hover,
.search-product-btn:focus {
  background-color: #07c;
}

.search-product-btn:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.search-product-btn:active {
  background-color: #0064bd;
  box-shadow: none;
}



.product-table{
    max-width: 98vw;
    font-size: 0.8em;
    overflow-x: scroll;
    border: 1px solid black;
    border-collapse: collapse;
    margin-bottom: 2em;
}
thead{
  background-color: #0095ff;
  color: white;
  font-size: 80%;
  
}
th{
  padding: 1.5em;
}
thead, tr,td,th{
  border : 1px solid black;
}
td{
  padding: 0.5em;
  text-align: center;
}
.product-delete-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
  color: #ffffff;
  background-color: red;
  border: none;
  border-radius: 5px;
}
.editProductLink{
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.product-edit-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
  color: #ffffff;
  background-color: #0095ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.product-edit-btn:hover{
  background-color: #0064bd;
}
.product-delete-btn:hover{
  cursor: pointer;
  background-color: rgb(195, 4, 4);
}