.FAQ_container {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.75em;
    margin-bottom: 1em;
    & + * {
      margin-top: 0.5em;
    }
  }
  
  .FAQ_item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: fit-content;
  }
  
  .FAQ_item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  
  .FAQ_title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    border: solid 1px #ccc;
    border-radius: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid currentColor;
    }
  
    &:hover,
    &.open {
      color: black;
      background-color: #b6d8f2;
    }
  
    &.open {
      &::after {
        content: "";
        border-top: 0;
        border-bottom: 5px solid;
      }
    }
  }
  
  .FAQ_content {
    padding: 1em 1.5em;
    border-radius: 1.5em;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    min-height: 5vh;
    font-size: 1em;
    border: 2px solid #b6d8f2;
    /* background-color: #5a6c7a; */
  }
  